<template>
  <b-card>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <b-row class="mb-1">
      <b-col cols="3" md="3" class="mb-1">
        <b-form-group label="Whitelabel">
          <v-select
            v-model="projects"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionsProjects"
            label=""
            @option:selected="indexBanners"
            :loading="isLoadingProjects"
          >
          </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="3" md="3" align-self="center" class="d-flex"> </b-col>
      <b-col md="6" class="d-flex align-items-center justify-content-end">
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
        <b-button class="text-nowrap" variant="primary" @click="addSegments()">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="text-nowrap"> {{this.$t('buttons.add-segment')}} </span>
        </b-button>
        <b-sidebar
          sidebar-class="sidebar-lg"
          id="sidebar-addEditBonus"
          bg-variant="white"
          right
          backdrop
          shadow
          no-header
          :visible="activeSidebarAddSegments"
          @change="changeSidebarActive"
        >
          <addEditSegments
            ref="addEditSegments"
            :activeSidebarAddSegments="activeSidebarAddSegments"
            @hiddentSidebar="activeSidebarAddSegments = false"
            @getList="getList()"
          />
        </b-sidebar>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="segmentOptions"
      :sort-options="{ enabled: false }"
      :pagination-options="{
        enabled: false,
      }"
      styleClass="vgt-table"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'status'">
          <b-form-checkbox
            v-model="props.row.status"
            name="checked"
            @change="changeStatus(props.row, props.row.status ? true : false)"
            switch
            inline
          />
        </div>
        <div v-else-if="props.column.field == 'amount.start'">
          <span class="align-middle ml-50"> {{ props.row.startAmount }}</span>
        </div>
        <div v-else-if="props.column.field == 'amount.end'">
          <span class="align-middle ml-50"> {{ props.row.endAmount }}</span>
        </div>
        <div v-else-if="props.column.field == 'WL'">
          <span class="align-middle ml-50"> {{ props.row.wl }}</span>
        </div>

        <div v-else-if="props.column.field == 'actions'">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editSegment(props.row)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('buttons.edit') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </vue-good-table>
    <section style="padding: 30px 0 0px 0px">
      <b-row>
        <b-col cols="6">
          <b-button class="text-nowrap" variant="primary" @click="synchronizeTotalBetPlayers()">
            <b-spinner
            v-if="loadingDataSynchronize"
            small
            label="Loading..."
            ></b-spinner>
          <span class="text-nowrap"> {{this.$t('buttons.synchronize')}} </span>
        </b-button>
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="current_page"
            :total-rows="total"
            :per-page="per_page"
            first-number
            @change="getList"
            align="right"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BListGroup,
  BListGroupItem,
  BLink,
  VBTooltip,
  BFormCheckbox,
  BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OpenGame from "../../games/openGame.vue";
import Ripple from "vue-ripple-directive";
import addEditSegments from "./addEditSegments.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BLink,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    OpenGame,
    addEditSegments,
    BFormCheckbox,
    BSpinner
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loadingData: false,
      searchQuery: "",
      activeSidebarAddSegments: false,
      columns: [
        { label: this.$t('message.tableHeader.name'), field: "name" },
        { label: this.$t('message.tableHeader.start-amount'), field: "amount.start" },
        { label: this.$t('message.tableHeader.end-amount'), field: "amount.end" },
        { label: "WL", field: "WL" },
        { label: this.$t('message.tableHeader.status'), field: "status" },
        { label: this.$t('message.tableHeader.action'), field: "actions" },
      ],
      isLoadingProjects: false,
      current_page: 1,
      per_page: 10,
      total: 0,
      customerSelected: {},
      segmentOptions: [],
      banners: [],
      projects: "",
      optionsProjects: [],
      checked: false,
      debouncedGetList: null,
      loadingDataSynchronize: false,
    };
  },
  computed: {
    customers() {
      return this.$store.state.customersModule.customers;
    },
  },
  watch: {
    searchQuery() {
      this.debouncedGetList();
    },
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
    
    async synchronizeTotalBetPlayers() {
      try {
        this.loadingDataSynchronize = true;
        let res = await this.$store.dispatch("bonus/syncTotalBetPlayers");
        console.log( 'hola', res);
         this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successful synchronization", 
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDataSynchronize = false;        
      }
    },
    async getList(page = 1, whiteLabel = null) {
      try {
        this.loadingData = true;
        let res = await this.$store.dispatch("bonus/getListSegments", {
          q: this.searchQuery,
          paginate: {
            page: page !== null ? page : this.current_page,
            perPage: this.per_page,
            sortDesc: true,
          },
        });
        this.segmentOptions = res.data.data;

        this.loadingData = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
      }
    },
    
    showModalOpenGame(game) {
      this.$refs.openGame.openModal = true;
      this.$refs.openGame.resGame = game;
      this.$refs.openGame.individualCred = this.projects;
      this.$refs.openGame.dataCustomer();
      this.$refs.openGame.resetModalData();
    },
    async indexBanners(select) {
      this.getList(1,select);
    },
    async projectsBanners() {
      try {
        this.isLoadingProjects = true;
        this.optionsProjects = await this.$store.dispatch(
          "betcoreBanners/getProjectsBanners"
        );

        this.isLoadingProjects = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingProjects = false;
      }
    },
    changeSidebarActive(val) {
      if (!val) this.$refs.addEditSegments.modeEdit = false;
      this.activeSidebarAddSegments = val;
    },
    addSegments() {
      this.activeSidebarAddSegments = true;
      this.$refs.addEditSegments.optionsWhiteLabel = this.optionsProjects;
    },

    editSegment(row) {
      this.activeSidebarAddSegments = true;
      this.$refs.addEditSegments.modeEdit = true;
      this.$refs.addEditSegments.optionsWhiteLabel = this.optionsProjects;
      this.$refs.addEditSegments.whiteLabel = row.wl;
      this.$refs.addEditSegments.formData = {
        _id: row._id,
        name: row.name,
        startAmount: row.startAmount,
        endAmount: row.endAmount,
        wl: row.wl,
        status: row.status,
      };
    },
    async changeStatus(row, status) {
      this.loadingData = true;
      try {
        await this.$store.dispatch("bonus/updateSegment", {
          status: status,
          _id: row._id,
        });
        this.getList();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Segment update successfully",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      } catch (error) {
        console.log(error.response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error add segments",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
  },

  mounted() {
    this.debouncedGetList = this.debounce(this.getList, 300); // 300ms de retraso
    this.getList();
    this.projectsBanners();
  },
  created() {},
  destroyed() {},
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
