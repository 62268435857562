<template>
  <div>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <!-- Header -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 v-if="!editProducts" class="mb-0">
        {{ modeEdit ? "Edit" : "New" }} Segments
      </h5>
      <h5 v-else class="mb-0">Update Products {{ formData.name }}</h5>
    </div>
    <!-- BODY -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(submitOn)"
        @reset.prevent="resetForm"
      >
        <validation-provider
          v-if="!editProducts"
          #default="validationContext"
          name="name"
          rules="required"
        >
          <b-form-group
            :label="$t('message.tableHeader.name')"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
            />
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="!editProducts"
          #default="validationContext"
          name="startAmount"
          rules="required"
        >
          <b-form-group
            class="mt-1"
            :label="$t('message.tableHeader.start-amount')"
            label-for="startAmount"
          >
            <b-form-input
              id="startAmount"
              v-model="formData.startAmount"
              autofocus
              type="number"
              :state="getValidationState(validationContext)"
              trim
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-if="!editProducts"
          #default="validationContext"
          name="endAmount"
          rules="required"
        >
          <b-form-group
            class="mt-1"
            :label="$t('message.tableHeader.end-amount')"
            label-for="endAmount"
          >
            <b-form-input
              id="endAmount"
              v-model="formData.endAmount"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              type="number"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-if="!editProducts"
          #default="validationContext"
          name="wl"
          rules="required"
        >
          <b-form-group class="mt-1" label="Whitelabel" label-for="wl">
            <!--  <b-form-input
              id="wl"
              v-model="formData.wl"
              autofocus
              :state="getValidationState(validationContext)"
              trim
            /> -->
            <v-select
              v-model="whiteLabel"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsWhiteLabel"
              label=""
              @option:selected="selectWl"
            >
            </v-select>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-if="!editProducts"
          #default="validationContext"
          name="status"
          rules="required"
        >
          <b-form-group
            class="mt-1"
            :label="$t('message.tableHeader.status')"
            label-for="status"
          >
            <b-form-checkbox
              v-model="formData.status"
              class="mr-0 mt-50"
              name="checked"
              switch
              inline
            />
          </b-form-group>
        </validation-provider>
        <!-- Credentials Products -->

        <!-- Form Actions -->
        <div class="d-flex align-items-center justify-content-between mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="modeEdit ? 'success' : 'primary'"
            class="mr-2"
            type="submit"
          >
            <feather-icon
              :icon="modeEdit ? 'RefreshCcwIcon' : 'PlusIcon'"
              class="mr-50"
            />
            <span class="align-middle">{{
              modeEdit ? $t("buttons.update") : $t("buttons.add")
            }}</span>
          </b-button>
          <b-button
            @click="$emit('hiddentSidebar')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-danger"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">{{ $t("buttons.cancel") }}</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BOverlay,
  BCard,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormCheckboxGroup,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import productStoreModule from "@/store/products";
import customerStoreModule from "@/store/customers";
import store from "@/store";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VJsoneditor from "v-jsoneditor/src/index";

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BCard,
    BCardBody,
    BFormCheckboxGroup,
    vSelect,
    BOverlay,
    ToastificationContent,
    BListGroup,
    BListGroupItem,
    BBadge,
    BInputGroupPrepend,
    BInputGroup,
    VJsoneditor,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    activeSidebarAddSegments: { type: Boolean, required: true },
  },

  data() {
    return {
      required,
      modeEdit: false,
      editProducts: false,
      loadingData: false,
      showSeachProduct: false,
      searchQueryProduct: "",
      optionsWhiteLabel: [],
      whiteLabel: "",
      formData: {
        name: null,
        startAmount: null,
        endAmount: null,
        wl: null,
        status: null,
      },
      configOptionsEditJson: {
        mode: "code",
        modes: ["code", "tree"],
        isExpand: true,
        height: "auto",
        navigationBar: true,
      },
    };
  },
  watch: {
    modeEdit(val) {
      if (!val) this.resetForm();
    },
    activeSidebarAddCustomer(val) {
      if (!val) {
        this.resetForm();
        this.editProducts = false;
      }
    },
  },
  methods: {
    submitOn() {
      if (this.modeEdit) this.updateSegments();
      else if (!this.modeEdit) this.saveCustomer();
    },
    // Save customer
    async saveCustomer() {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch(
          "bonus/createSegment",
          this.formData
        );
        this.resetForm();
        this.$emit("hiddentSidebar");
        this.$emit("getList");
        this.$emit("showConfig", res.data);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Segment added successfully",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      } catch (error) {
        console.log(error.response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error adding products add",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    selectWl(val) {
      this.formData.wl = val;
      this.whiteLabel = val;
    },
    // Save customer
    async updateSegments() {
      try {
        this.loadingData = true;
        await this.$store.dispatch("bonus/updateSegment", this.formData);
        this.modeEdit = false;
        this.$emit("hiddentSidebar");
        this.$emit("getList");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Segment update successfully",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      } catch (error) {
        console.log(error.response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error add segments",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    //---Validate State Fields
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    resetForm() {
      this.formData = {
        name: null,
        startAmount: null,
        endAmount: null,
        wl: null,
        status: true,
      };
      this.$refs.refFormObserver.reset();
      this.whiteLabel = "";
      this.optionsWhiteLabel = [];
    },
  },
  created() {
    // Register module
    if (!store.hasModule("customerModule"))
      store.registerModule("customerModule", customerStoreModule);
    if (!store.hasModule("productsModule"))
      store.registerModule("productsModule", productStoreModule);
  },
  destroyed() {
    if (store.hasModule("customerModule"))
      store.unregisterModule("customerModule");
    if (store.hasModule("productsModule"))
      store.unregisterModule("productsModule");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
